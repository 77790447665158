import CustomBar from '../../components/CustomBar';
import {Grid, Box} from '@mui/material';

import "../../index.css"

export const mooresPath = "https://open.spotify.com/track/4HD4hVe99pZf9qtLMWeTr0?si=195972d122044235"
export const rsioPath = "https://open.spotify.com/track/4ZJpGTLaaapV7LfV6lBz7v?si=8324b482b6ea4214";
export const greeblesPath = "https://open.spotify.com/album/4VN31XzLaeTesXL67Mtxgu?si=wX6RVQw3TW--ANe_Hbi7FQ"
export const fearsomeEngineSinglePath = "https://ripship.bandcamp.com/track/fearsome-engine"
export const fearsomeEngineAlbumPath = "https://ripship.bandcamp.com/album/fearsome-engine"

export const mooresAltText = "Spikey heavy metal font saying Moore's Law, with a cut-out revealing a dreamy green and purple landscape"
export const rsioAltText = "Spikey heavy metal font saying Ripship is Online, with a cut-out revealing a dreamy green and blue landscape"
export const greeblesAltText = "Greebles EP cover, A cluster of metallic objects floating in a black void"
export const fearsomeSingleAltText = "Spikey heavy metal font saying Fearsome Engine, with a cut-out revealing a dreamy pink and purple landscape"
export const fearsomeAlbumAltText = "Fearsome Engine album cover, a surreal AI generated landscape with pink and purple grass in the foreground, pink clouds against a blue sky in the background, and a strange steam-punk machine in the centre"

function Releases() {

    return(
        <div>
            <CustomBar></CustomBar>
            <div className='layout'>
                <h1>Releases</h1>
                <div style = {{marginTop: '20px', marginBottom: '20px'}}>
                <Grid container spacing={2}>
                <Grid item md = {4} xs = {12}>
                        <ReleaseGrid path = {fearsomeEngineAlbumPath} altText={fearsomeAlbumAltText} text = "Fearsome Engine - Album" date = "16/12/22" src = {require("../../resources/images/fearsomeEngine/FearsomeEngineFrontCoverSmall.png")}></ReleaseGrid>
                    </Grid>
                    <Grid item md = {4} xs = {12}>
                        <ReleaseGrid path ={fearsomeEngineSinglePath} altText={fearsomeSingleAltText} text = "Fearsome Engine - Single" date = "2/12/22" src = {require("../../resources/images/fearsomeSingle/Fearsome_Engine_Single_Cover.png")}></ReleaseGrid>
                    </Grid>
                    <Grid item md = {4} xs = {12}>
                        <ReleaseGrid path ={mooresPath} altText={mooresAltText} text = "Moore's Law - Single" date = "7/10/22" src = {require("../../resources/images/mooresLaw/Moores_Law_Single_Cover.jpg")}></ReleaseGrid>
                    </Grid>
                    <Grid item md = {4} xs = {12}>
                        <ReleaseGrid path ={rsioPath} altText={rsioAltText} text = "Ripship is Online - Single" date = "26/08/22" src = {require("../../resources/images/ripshipIsOnline/Ripship_is_Online_Single_Cover.png")}></ReleaseGrid>
                    </Grid>
                    <Grid item md = {4} xs = {12}>
                        <ReleaseGrid path ={greeblesPath} altText={greeblesAltText} text = "Greebles - EP" date = "8/05/20" src = {require("../../resources/images/greebles/EP_Cover.jpg")}></ReleaseGrid>
                    </Grid>
                    <Grid item md = {12} xs = {12}>
                        <ReleaseVideo text = "Fearsome Engine - Video" date = "02/12/22" path = "E9KaY3Nwx2o"></ReleaseVideo>
                    </Grid>
                    <Grid item md = {12} xs = {12}>
                        <ReleaseVideo text = "Ripship is Online Promo Video" date = "29/08/22" path = "CN-0DuWQqf0"></ReleaseVideo>
                    </Grid>
                    <Grid item md = {12} xs = {12}>
                        <ReleaseVideo text = "Lube the Cube - Video" date = "19/11/20" path = "UUMGH_MAY88"></ReleaseVideo>
                    </Grid>
                </Grid>
            </div>
            </div>
        </div>

    );
}

function ReleaseGrid(props) {

    return(
        <a href={props.path} style={{textDecoration: 'none', color: 'inherit'}}>
            <Box className={'divHover divBorder'}>
                <img style = {{ maxHeight: '100%', maxWidth: '100%'}} src={props.src} alt={props.altText}/>
                <div className={'releaseText'}>{props.text}</div>
                <div className={'releaseDateText'}>{props.date}</div>
            </Box>
        </a>
    )

}

export function ReleaseVideo(props){

    return(
        <div className={'divHover divBorder'}>
            <Box>
                <Box style={{padding: '4px'}}>
                    <iframe align= "center" style={{flexGrow: 1, height: '45vw', width: '100%'}} src={("https://www.youtube.com/embed/" + props.path)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </Box>
                <div className={'releaseText'}>{props.text}</div>
                <div className={'releaseDateText'}>{props.date}</div>
            </Box>
        </div>
    )

}

export default Releases;