import {Grid, Box} from '@mui/material';
import useWindowDimensions from '../../components/windowDimensions';
import CustomBar from '../../components/CustomBar';
import * as React from 'react';
import logo from '../../resources/images/metal.png'
import { blue } from '@mui/material/colors';

export const bandcampPath = "https://ripship.bandcamp.com";
export const spotifyPath = "https://open.spotify.com/artist/1yx7HUETsdOE9oP6ISSVSX?si=NS-IbuAwSHaOMiOR70V1TA";
export const instagramPath = "https://www.instagram.com/ripship_/";
export const facebookPath = "https://www.facebook.com/ripshipband";
export const onlyfansPath = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";

const socialButtons = 
[
    {path: bandcampPath, primary: "red", secondary: "lightBlue", label: "bandcamp"},
    {path: spotifyPath, primary: "yellow", secondary: "blue", label: "spotify"},
    {path: instagramPath, primary: "lightGreen", secondary: "blue", label: "instagram"},
    {path: facebookPath, primary: "blue", secondary: "red", label: "facebook"},
    {path: onlyfansPath, primary: "orange", secondary: "purple", label: "onlyfans"}
]

//props = primary, secondary, bgColor, textColor, link, label    
export function LinkButton(props) {
    return (
        <a href={props.path} target="_blank" className='link-button' rel="noreferrer"> 
            <Box sx={{border:1, bgcolor: props.bgColor, color: props.textColor,
                ':hover': {
                bgcolor: props.primary, 
                color: props.secondary},
                p: 2
            }
            }>
            {props.label}</Box>
        </a>
    );
}

function Home() {

    const { width } = useWindowDimensions();

    return(
        <div>
        <CustomBar></CustomBar>
        <div className={'layout'}>
            { width < 600 &&
                <img
                alt='spiky heavy metal band logo'
                src={logo} loading="eager"
                style = {{maxHeight: (width*0.75*0.9), maxWidth: (width*0.9)}}
                />
            }
            { width >= 600 &&
                <img
                alt='spiky heavy metal band logo'
                src={logo} loading="eager"
                style = {{maxHeight: (width*0.75*0.65), maxWidth: (width*0.65), marginTop: '-4%', marginBottom: '-13%', zIndex: -100}}
                />
            }
            <Grid container spacing={2}>
                {socialButtons.map((item, index) => (
                    <Grid item md={2.4} xs={index > 2? 6 : 4}>
                        <LinkButton path = {item.path} primary = {item.primary} secondary = {item.secondary} label = {item.label} bgColor={"white"} textColor = {"black"}/>
                    </Grid>
                ))}
            </Grid>
        </div>
        </div>

    );
}

export default Home;