import CustomBar from "../../components/CustomBar";
import "../../index.css";
import { Grid, Box } from "@mui/material";
import valhalla from "../../resources/images/valhalla.JPG";
import { mediumBio, longBio } from "../../components/BioBox";

const valhallaAltText =
  "A moody black and white photo of the band playing on the cluttered stage of Valhalla, a bar in Wellington";

function About() {
  return (
    <div>
      <CustomBar></CustomBar>
      <div className="layout">
        <h1>About</h1>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item md={6} xs={12} display="flex" flexDirection="column">
              <Box className={"gridTextBox"}>
                <>{longBio}</>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <img
                className="gridImage"
                src={valhalla}
                alt={valhallaAltText}
                loading="lazy"
              />
              <div className="gridImageSubtext">
                <text>photo by Logan McAllister</text>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default About;
