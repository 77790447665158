import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";

export const shortBio =
  "Ripship are a sci-fi psych-rock duo hailing from Pōneke. The prolific performers shake venues with heavy riffs, off-kilter beats and deep synth loops. Science fiction and technological anxiety influence both the timbre and subject matter of Ripship’s songs, with vocoded lyrics touching on AI uprising, interstellar travel and the heat death of the universe.";
export const mediumBio =
  "Ripship are a sci-fi psych-rock duo hailing from Pōneke. The prolific performers shake venues with heavy riffs, off-kilter beats and deep synth loops. Science fiction and technological anxiety influence both the timbre and subject matter of Ripship’s songs, with vocoded lyrics touching on AI uprising, interstellar travel and the heat death of the universe.\n" +
  "\n" +
  "Callum Lincoln (guitar, synth, vocals) and Eva-Rae McLean (drums, vocals) met at a gig in 2018, but unknowingly crossed paths many times in the Auckland all-ages scene circa 2016. Ripship's live performances are well-reviewed, with audiences and critics noting the surprisingly massive sound for a two-piece. \n";
export const longBio = (
  <text>
    <p style={{ marginTop: "0px" }}>
      Ripship are a sci-fi psych-rock duo hailing from Pōneke. The prolific
      performers shake venues with heavy riffs, off-kilter beats and deep synth
      loops. Science fiction and technological anxiety influence both the timbre
      and subject matter of Ripship’s songs, with vocoded lyrics touching on AI
      uprising, interstellar travel and the heat death of the universe.
    </p>
    <p>
      Callum Lincoln (guitar, synth, vocals) and Eva-Rae McLean (drums, vocals)
      met at a gig in 2018, but unknowingly crossed paths many times in the
      Auckland all-ages scene circa 2016.
    </p>
    <p>
      The duo's first release <i>Greebles</i> is a six-track EP. Reviews praised
      the variety of genres and the unique timbres achieved with a lo-fi setup.
      In 2022 the band released their debut LP <i>Fearsome Engine</i>, a
      nine-track monster whose refined production contrasted the D.I.Y sound of{" "}
      <i>Greebles</i>, and which was released on vinyl by 1:12 Records.
    </p>
    <p style={{ marginBottom: "0px" }}>
      Ripship's live performances are well-reviewed, with audiences and critics
      noting the surprisingly massive sound for a two-piece.
    </p>
  </text>
);

function BioBox() {
  const [value, setValue] = React.useState("2");

  function handleChange(_, newValue) {
    setValue(newValue);
  }

  return (
    <div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            centered={true}
            TabIndicatorProps={{
              style: { background: "CornflowerBlue" },
            }}
          >
            <Tab label="Short Bio" value="1" style={{ color: "black" }} />
            <Tab label="Medium Bio" value="2" style={{ color: "black" }} />
            <Tab label="Long Bio" value="3" style={{ color: "black" }} />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ textAlign: "left" }}>
          {shortBio}
        </TabPanel>
        <TabPanel value="2" style={{ textAlign: "left" }}>
          {mediumBio}
        </TabPanel>
        <TabPanel value="3" style={{ textAlign: "left" }}>
          {longBio}
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default BioBox;
