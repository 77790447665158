import { Box} from '@mui/material';

const routes = [
    {label: "home", path: "/"},
    {label: "releases", path: "/releases"},
    {label: "about", path: "/about"},
    {label: "contact", path: "/contact"}
]

export function LinkButton(props) {
    return (
        <a href={props.path} className='link-button'> 
            <Box sx={{
                bgcolor: props.bgColor,
                color: props.textColor,
                p: 2,
                ':hover': 
                {
                    bgcolor: "yellow", 
                    color: "black"
                }
            }
            }>
            {props.label}</Box>
        </a>
    );
}

//Home bar
function CustomBar(props){

    return (
        <nav>
            <div className = 'navigation-bar'>
            {
                routes.map(item => (
                    <LinkButton path = {item.path} label = {item.label} bgColor={"transparent"} textColor = {props.isHome? "white" : "black"}/>))
            }
            </div>
        </nav>
    )
}

export default CustomBar