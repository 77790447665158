import couchDark from '../resources/images/couchDark.jpeg'
import couchLight from '../resources/images/couchLight.JPG'
import kroad from '../resources/images/kroad.JPG'
import bw from "../resources/images/heidiPhotos/Ripship-BlackWhiteMid.jpg"
import gear from "../resources/images/heidiPhotos/Ripship-WithGear.jpg"
import darkClose from "../resources/images/heidiPhotos/Ripship-DarkCloseup.jpg"
import {Grid} from "@mui/material";


function Gallery(){
    return(
        <div>
            <Grid container spacing={2}>

                <Grid item md = {6} xs = {12}>
                    <img
                        src={bw}
                        alt={"image"}
                        loading="lazy"
                        style = {{width: '100%'}}
                    />
                    <text className='myText' style = {{fontSize: '50%'}} >photo by Heidi Watson</text>
                </Grid>
                <Grid item md = {6} xs = {12}>
                    <img
                        src={kroad}
                        alt={"image"}
                        loading="lazy"
                        style = {{width: '100%'}}
                    />
                </Grid>

                <Grid item md = {6} xs = {12}>
                    <img
                        src={gear}
                        alt={"image"}
                        loading="lazy"
                        style = {{width: '100%'}}
                    />
                    <text className='myText' style = {{fontSize: '50%'}} >photo by Heidi Watson</text>
                </Grid>
                <Grid item md = {6} xs = {12}>
                    <img
                        src={darkClose}
                        alt={"image"}
                        loading="lazy"
                        style = {{width: '100%'}}
                    />
                    <text className='myText' style = {{fontSize: '50%'}} >photo by Heidi Watson</text>
                </Grid>
                <Grid item md = {6} xs = {12}>
                    <img
                        src={couchDark}
                        alt={"image"}
                        loading="lazy"
                        style = {{width: '100%'}}
                    />
                </Grid>
                <Grid item md = {6} xs = {12}>
                    <img
                        src={couchLight}
                        alt={"image"}
                        loading="lazy"
                        style = {{width: '100%'}}
                    />
                </Grid>

            </Grid>
        </div>
    );

}

export default Gallery;