import CustomBar from "../../components/CustomBar";
import "../../index.css";
import { Grid, Box } from "@mui/material";
import { facebookPath, instagramPath } from "../home/Home";
import octopus from "../../resources/images/octopus.jpeg";

const octopusAltText =
  "A tacky tourist photo of Callum and Rae from Kelly Tarltons, edited to look like they are underwater with a giant octopus";
const email = "ripshipband@gmail.com";

function Contact() {
  return (
    <div>
      <CustomBar></CustomBar>
      <div className="layout">
        <h1>Contact</h1>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item md={6} xs={12} display="flex" flexDirection="column">
              <Box className={"gridTextBox"}>
                For gigs and general shenanigans email us at{" "}
                <a href={`mailto:${email}`}>{email}</a>
                <p>
                  <text>Ripship is online at </text>
                  <a
                    style={{ color: "blue" }}
                    href={instagramPath}
                    target="blank"
                  >
                    {" "}
                    instagram (@ripship_){" "}
                  </a>
                  <text> and </text>
                  <a
                    style={{ color: "blue" }}
                    href={facebookPath}
                    target="blank"
                  >
                    {" "}
                    facebook{" "}
                  </a>
                </p>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <img
                className="gridImage"
                src={octopus}
                alt={octopusAltText}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Contact;
